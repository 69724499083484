import React, { useEffect } from "react";
import Header from "components/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/common");
    }
  }, [location.pathname]);

  /**
   * @comment 대시보드 API 수정되면 살리기
   */
  // if (location.pathname === "/dashboard") {
  //   return (
  //     <div className="flex min-h-[900px] w-full min-w-[1280px] flex-col">
  //       <Header />
  //       <Outlet />
  //     </div>
  //   );
  // }

  return (
    <div className="flex h-screen min-h-[900px] w-full min-w-[1280px] flex-col">
      <Header />
      <Outlet />
    </div>
  );
};

export default Main;
