import React, { useMemo, useState } from "react";
import { ReactComponent as TopLogo } from "images/login-top-logo.svg";
import { ReactComponent as MailIcon } from "images/ic-mail-outline.svg";
import { ReactComponent as PhoneIcon } from "images/ic-phone-outline.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { encryptedUserState } from "recoil/atoms";

import { useMutation } from "react-query";
import Footer from "components/Footer";
import cretaApi from "service/cretaApi";
import { AuthStorage, setCookie, setStorage } from "constants/constants";
import commonApi from "service/commonApi";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initErrors = useMemo(() => ({ email: "", password: "" }), []);
  const [errors, setErrors] = useState(initErrors);
  const hasError = Object.values(errors).some((error) => error !== "");

  const [encryptedUser, setEncryptedUser] = useRecoilState(encryptedUserState);

  const adminCheckMutation = useMutation({
    mutationFn: commonApi.getAdminUserStatus,
    onSuccess: (response: any, session) => {
      console.log(`variables`, session);

      if (!response.data?.is_staff && !response.data?.is_superuser) {
        console.log(`response.data`, response.data);
        alert("error! 관리자에게 문의하세요.");
        return;
      }

      setCookie(AuthStorage.ACCESS_TOKEN, session.sessionToken);
      setStorage(AuthStorage.ACCESS_TOKEN, session.sessionToken);

      setStorage(AuthStorage.REFRESH_TOKEN, session.refreshToken);

      navigate("/");
    },
    onError: (e) => {
      console.log("error: ", e);
      alert("error! 관리자에게 문의하세요.");
    },
  });

  const mutation = useMutation({
    mutationFn: cretaApi.postCredentialLogin,
    onSuccess: (response, variables) => {
      const { session, user } = response;
      console.log(`user`, user);
      setEncryptedUser({
        email: variables.email,
        password: variables.password,
      });

      setTimeout(() => {
        adminCheckMutation.mutate(session);
      }, 500);
    },
    onError: () => {
      alert("error! 어드민 계정정보를 정확히 입력해주세요.");
    },
  });

  const onClickSubmit = async () => {
    const errors = { email: "", password: "" };
    if (email === "") errors.email = "Please enter a valid email address.";
    if (password === "") errors.password = "Please enter a valid password.";

    if (errors.email.length > 0 || errors.password.length > 0) {
      setErrors(errors);
      return;
    }

    setErrors(initErrors);
    mutation.mutate({ email, password, authProvider: "credential" });
  };

  // if (isLoading || (isFetched && isSuccess)) return null;

  return (
    <div className="relative flex h-screen min-h-[900px] min-w-[1280px] flex-col items-center from-secondary-100 to-primary-200 bg-gradient-[123deg]">
      <div className="flex flex-col items-center">
        <TopLogo className="h-[87px] w-[344px]" />

        <div className="mt-[100px] text-center text-[78px] font-black text-white drop-shadow-[0_4px_1px_rgba(0,0,0,0.2)]">
          SUPER CLUB ADMIN
        </div>

        <div className="relative mt-[150px] flex min-h-[200px] w-[430px] flex-col ">
          {hasError && (
            <div className="absolute top-[-82px] w-full whitespace-pre-line rounded-[4px] bg-[#21272A] p-[16px] text-[12px] font-medium leading-[16px] text-white">
              {`Please enter the email and password of the admin account.\nPlease enter uppercase and lowercase letters.`}
            </div>
          )}

          <input
            className="flex h-[52px] flex-col rounded-[8px] border border-solid border-primary-100 bg-white p-[14px_16px] text-[16px] leading-[24px] placeholder:text-[#BDBDBD] focus:border-point-100"
            value={email}
            onChange={(e) => {
              setErrors((prev) => ({ ...prev, email: "" }));
              setEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && onClickSubmit();
            }}
            placeholder="Enter your email."
          />
          {errors.email !== "" && <div className="mb-2.5 mt-2 font-medium text-[#EB3B45] ">{errors.email}</div>}

          <div className="mt-[18px]" />

          <input
            type="password"
            className="flex h-[52px] flex-col rounded-[8px] border border-solid border-primary-100 bg-white p-[14px_16px] text-[16px] leading-[24px] placeholder:text-[#BDBDBD] focus:border-point-100"
            value={password}
            onChange={(e) => {
              setErrors((prev) => ({ ...prev, password: "" }));
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && onClickSubmit();
            }}
            placeholder="Enter your password."
          />
          {errors.password !== "" && <div className="mb-2.5 mt-2 font-medium text-[#EB3B45]">{errors.password}</div>}

          <div className="mt-[30px]" />

          <button
            className="flex h-[52px] flex-col items-center justify-center rounded-[8px] border border-solid border-white bg-[rgba(0,0,0,0)] text-[15px] leading-[22px] text-white hover:bg-[rgba(0,0,0,0.08)]"
            onClick={onClickSubmit}
          >
            Submit
          </button>

          <div className="mt-[16px] flex text-center text-[14px] leading-[24px] text-white opacity-50">
            Only authorized people can access it. If want to know password, please contact the admin.
          </div>

          <div className="mt-[32px] flex justify-center gap-[50px]">
            <button className="flex items-center gap-[8px]">
              <MailIcon />
              <span className="text-[14px] leading-[24px] text-white opacity-50">sun@runners.im</span>
            </button>

            <button className="flex items-center gap-[8px]">
              <PhoneIcon />
              <span className="text-[14px] leading-[24px] text-white opacity-50">sun@runners.im</span>
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
