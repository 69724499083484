import axios from "axios";

export interface PostCredentialLoginPayload {
  email: string;
  password: string;
  authProvider: "credential";
}

const authToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyS2V5IjoiU1VQRVJDTFVCX0RpdmVyc2UiLCJpYXQiOjE3MjczMjc5NTJ9.T83vttW__Q6szkdfZYvMxMGI2EbMusegaAkCBahNqX4`;

const headers = {
  Authorization: `Bearer ${authToken}`,
};

const axiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_CRETA_AUTH_API}`,
  headers,
});

const cretaApi = {
  postCredentialLogin: async (payload: PostCredentialLoginPayload) => {
    const { data } = await axiosApi("/api/auth/login", {
      method: "POST",
      data: payload,
    });
    return data;
  },
};

export default cretaApi;
